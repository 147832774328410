import { useRouter } from 'next/router'

import Preloader from '@/components/base/Preloader'

import { useSession } from '@/contexts/Session'

const Guest = ({ children }) => {
    const { push, asPath } = useRouter()
    const { isAuthenticated, nextURL, user } = useSession({
        required: false,
        onAuthenticated() {
            if (
                user &&
                (nextURL
                    ? nextURL
                    : `/projects?type=${
                          user?.projectTypeAccess === 'all'
                              ? 'housetypes'
                              : user?.projectTypeAccess
                      }&standard=${
                          user?.standardAccess === 'both'
                              ? ''
                              : user?.standardAccess
                      }`) !== asPath
            ) {
                if (
                    ['Admin', 'System User', 'Manager'].includes(
                        user?.roleTitle
                    )
                ) {
                    push(
                        nextURL
                            ? nextURL
                            : `/projects?type=${
                                  user?.projectTypeAccess === 'all'
                                      ? 'housetypes'
                                      : user?.projectTypeAccess
                              }&standard=${
                                  user?.standardAccess === 'both'
                                      ? ''
                                      : user?.standardAccess
                              }`
                    )
                } else {
                    push(
                        `/${user?.roleTitle === 'Product Library Admin' ? 'products?productGroup=standard' : 'hardware-sets'}`
                    )
                }
            }
        },
    })

    if (!isAuthenticated) {
        return children
    }

    return <Preloader></Preloader>
}

export default Guest
