import React, { useState } from 'react'
import SidebarNavigation from '@/components/base/SidebarNavigation'
import { useRouter } from 'next/router'
import Logo from '@/components/base/Logo'
import Button from '@/components/base/Button'
import Loader from '@/components/base/Loader'
import styles from '@/styles/Sidebar.module.css'
import { useSession } from '@/contexts/Session'

const Sidebar = () => {
    const router = useRouter()
    const [activeMenu, setActiveMenu] = useState(0)
    const { scopes, user } = useSession()
    const menuItems = [
        {
            icon: 'icon-hex',
            title: 'PROJECTS',
            path: '/projects',
            query: {
                type: 'schedule',
                standard: '',
            },
        },
        {
            icon: 'icon-crm',
            title: 'CRM',
            path: '/crm',
            query: {
                type: 'active',
            },
        },
        {
            icon: 'icon-backlog',
            title: 'HARDWARE SETS',
            path: '/hardware-sets',
        },
        {
            icon: 'icon-folder',
            title: 'PRODUCTS',
            path: '/products',
            query: {
                productGroup: 'standard',
            },
        },
        {
            icon: 'icon-building',
            title: 'COMPANY',
            path: '/company',
        },
        {
            icon: 'icon-address-book',
            title: 'CONTACTS',
            path: '/contacts',
        },
        {
            icon: 'icon-users',
            title: 'USERS',
            path: '/users',
            query: {
                isUserListing: true,
                status: 'active',
            },
        },
        {
            icon: 'icon-settings',
            title: 'SETTINGS',
            path: '/configurations',
        },
    ].filter((navigation) => {
        if (
            user?.roleTitle === 'System User' &&
            user?.userGroup === 'rep' &&
            ['PRODUCTS', 'HARDWARE SETS'].includes(navigation.title)
        ) {
            return false
        }
        if (
            user?.roleTitle === 'System User' &&
            user?.userGroup === 'estimator' &&
            navigation.title === 'CRM'
        ) {
            return false
        }
        if (
            user?.roleTitle === 'System User' &&
            navigation?.title === 'PROJECTS'
        ) {
            if (user?.projectTypeAccess !== 'all') {
                navigation.query.type = user?.projectTypeAccess
            }
            if (user?.standardAccess !== 'both') {
                navigation.query.standard = user?.standardAccess
            }
        }
        return (
            navigation.label === 'Sign out' ||
            scopes.includes(navigation?.path?.slice(1)?.replace('/', '-'))
        )
    })
    const handleClick = (index) => {
        setActiveMenu(index)
    }
    const activeNavigationClass = (link, isParent = true, index) => {
        const { asPath } = router
        if (isParent) {
            if (asPath.startsWith(link)) {
                return styles.active
            }
        } else {
            if (index === 0 && asPath !== link) {
                return ''
            } else if (asPath.startsWith(link)) {
                return styles.active
            }
        }
    }
    const { push } = useRouter()
    const { authToken, removeAuthToken } = useSession()

    const signOut = async () => {
        const signOutURL = `${process.env.NEXT_PUBLIC_API_URL}/auth/logout`

        const fetchOptions = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authToken?.accessToken}`,
            },
        }
        await fetch(signOutURL, fetchOptions)
        removeAuthToken('accessToken', {
            path: '/',
            sameSite: true,
        })
        push('/')
    }

    return (
        <>
            <div
                className={`${styles.sidebar} ${
                    scopes.includes('library-admin') && '!justify-start pt-28'
                }`}
            >
                <Logo
                    link={
                        scopes.includes('library-admin')
                            ? {
                                  pathname:
                                      user?.roleTitle ===
                                      'Product Library Admin'
                                          ? '/products'
                                          : '/hardware-sets',
                                  query:
                                      user?.roleTitle ===
                                      'Product Library Admin'
                                          ? { productGroup: 'standard' }
                                          : {},
                              }
                            : {
                                  pathname: '/projects',
                                  query: {
                                      type: 'schedule',
                                      standard: '',
                                  },
                              }
                    }
                    src="/logo-inverse.svg"
                    width={48.53}
                    height={42}
                    className="w-[48.53px] h-[42px] absolute top-6"
                />
                <div className="scrollable max-h-[calc(100vh-155px)] pr-1 -mr-2 overflow-x-hidden">
                    {user?.userGroup && (
                        <SidebarNavigation
                            activeMenu={activeMenu}
                            activeNavigationClass={activeNavigationClass}
                            menuItems={menuItems}
                            handleClick={handleClick}
                            userGroup={user?.userGroup}
                        />
                    )}
                    {!user?.userGroup && (
                        <div className="flex flex-col items-center justify-center">
                            <div className="mb-2 transition-all redacted-placeholder h-7 w-7 bg-interface-200"></div>
                            <div className="w-16 h-3 transition-all redacted-placeholder bg-interface-300"></div>
                        </div>
                    )}
                </div>
                <div className="signout-section">
                    <Button
                        link="/"
                        text="SIGN OUT"
                        click={() => signOut()}
                        type="button"
                        icon="logout"
                        labelSize="text-xs"
                        position="flex-col"
                        iconStyle="static mb-3 hover:text-common-black"
                        variant="sidebarLink"
                        modification="px-0 hover:text-common-black h-[72px]"
                    />
                </div>
            </div>
        </>
    )
}

export default Sidebar
